<template>
  <div class="home">
    <!-- TODO -->
    <v-card class="mx-auto">
      <v-list>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-list-item>
                <v-list-item-avatar size="100">
                  <v-img
                    contain
                    darkd
                    lazy-src
                    v-if="$store.state.profile.photoUrl"
                    :aspect-ratio="16 / 9"
                    :src="$store.state.profile.photoUrl"
                    alt="頭像"
                  ></v-img>
                  <v-img
                    contain
                    darkd
                    lazy-src
                    v-else
                    :aspect-ratio="16 / 9"
                    src="@/assets/images/grey_silhouette.png"
                    alt="頭像"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="px-0 mr-n12">
                  <v-card-title class="text-h5">
                    {{ $store.state.profile.name }}
                  </v-card-title>
                  <v-card-subtitle>{{ $store.state.profile.email }}</v-card-subtitle>
                </v-list-item-content>
                <v-list-item-action class="px-0 mx-0">
                  <v-btn icon @click="msgPop('此功能尚未開放')">
                    <v-icon>mdi-square-edit-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-col>
          </v-row>
        </v-container>
      </v-list>
      <v-divider />
      <v-card-text class="justify-center">
        <v-card-title class="justify-center text-h5">待診病例</v-card-title>
        <v-data-table
          mobile-breakpoint
          multi-sort
          show-select
          dense
          v-model="selected"
          :headers="headers"
          :items="patientListItems"
          item-key="medicalOrderNumber"
          class="elevation-1"
        >
          <!-- <template #[`item.registerTime`]="{ item }">
            {{ item.registerTimeString }}
          </template> -->
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="success" :disabled="selected.length === 0" type="submit" @click="submit()">
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay v-model="requesting">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-footer dark padless fixed>
      <v-container class="pa-0 d-flex justify-center" fluid dark>
        <div class="text-caption">
          Copyright ©
          <a class="d-inline-block" :href="`mailto:${$store.state.package.author.url}`">
            {{ $store.state.package.author.name }}
          </a>
          {{ new Date().getFullYear() }}
        </div>
      </v-container>
    </v-footer>
    <message-snackbar
      v-model="$data.$_mixin_messageSnackbar_showing"
      :type="$data.$_mixin_messageSnackbar_type"
      :message="$data.$_mixin_messageSnackbar_message"
      :action="$data.$_mixin_messageSnackbar_action"
      :timeout="$data.$_mixin_messageSnackbar_timeout"
    />
  </div>
</template>

<script>
import Vue from 'vue';

import messageSnackbar, { MessageSnackbarType } from '@/mixins/messageSnackbar';
// import { firestore } from '@/firebase';

export default Vue.extend({
  name: 'Home',
  mixins: [messageSnackbar],
  data: () => ({
    headers: [
      {
        text: '時段',
        value: 'period',
      },
      {
        text: '診號',
        value: 'consultationNo',
      },
      {
        text: '姓名',
        value: 'name',
        align: 'start',
        sortable: false,
      },
      {
        text: '病歷號',
        value: 'medicalOrderNumber',
        sortable: false,
      },
      {
        text: '健保',
        value: 'status',
        sortable: false,
      },
      {
        text: '科別',
        value: 'department',
        sortable: false,
      },
      {
        text: '卡號',
        value: 'cardId',
      },
      {
        text: '掛號時間',
        value: 'registerTime',
      },
      {
        text: '欠費',
        value: 'arrears',
      },
      {
        text: '備註',
        value: 'remark',
        sortable: false,
      },
    ],
    selected: [],
    requesting: false,
  }),
  methods: {
    msgPop(str) {
      this.$_mixin_messageSnackbar_show(MessageSnackbarType.error, str, 'close');
    },
    async submit() {
      // this.selected.forEach((item) => {
      //   firestore.collection('patients').doc(item.id).update({ selected: true });
      //   firestore
      //     .collection('users')
      //     .doc(this.$store.state.profile.uid)
      //     .update({ [`ownPatients.${item.id}`]: firestore.collection('patients').doc(item.id) });
      // });
      for (let i in this.selected) {
        const updateParameters = {
          key: this.selected[i].patientInfoKey,
          updateSelected: { selected: true },
        };
        await this.$store.dispatch('updatePatientInfo', updateParameters);
      }
      await this.$store.dispatch('fetchPatientInfo');
      this.selected = [];
    },
  },
  computed: {
    patientListItems() {
      //   const patientListItems = this.$store.state.patientCollection.filter(
      //     (item) => item.selected == false,
      //   );
      const patientListItems = this.$store.state.patientInfo.filter(
        (item) => item.selected === false,
      );
      // patientListItems.forEach((item) => {
      //   item.registerTimeString = item.registerTime.toDate().toLocaleString();
      // });
      return patientListItems;
    },
  },
  async created() {
    this.requesting = true;
    this.requesting = false;
    // await this.$store.dispatch('bindWaitingPatient');
  },
});
</script>
